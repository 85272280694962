<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 row">
        <h2
          class="col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          タイミングメール一覧
        </h2>
        <div
          class="col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"
        >
          <button class="button-create-user" @click="CreateTimingEmail()">
            新規追加
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <div class="row p-3">
        <div class="row-input col-sm-12 col-md-6 col-lg-4 px-0">
          <label class="w-100 font-weight-bold pl-0">管理名</label>
          <input class="form-control w-100" v-model="management_name" />
        </div>
        <div class="row-input col-sm-12 col-md-6 col-lg-4 px-0 pl-lg-3">
          <label class="w-100 font-weight-bold pl-0">メールタイトル</label>
          <input class="form-control w-100" v-model="email_title" />
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListTimingmails()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <Tables
        :items="listTimingMail.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:id="{ index }">
          <td>{{ listTimingMail.current_page * limit - limit + index + 1 }}</td>
        </template>
        <template v-slot:status_order="{ item }">
          <td v-if="item.status_order == 1">未承認</td>
          <td v-else-if="item.status_order == 2">承認済み</td>
          <td v-else-if="item.status_order == 3">キャンセル</td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:logout_at="{ item }">
          <td>
            {{
              new Date(item.logout_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center flex-nowrap">
            <div class="d-flex">
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'Edit TimingMail'
                    : 'Edit Timing Mail domain',
                  params: { shopId: shop_id, id: item.id },
                  query: {
                    no: listTimingMail.current_page * limit - limit + index + 1,
                  },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 text-nowrap"
                  color="primary"
                  square
                  variant="outline"
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  編集
                </CButton>
              </router-link>
              <CButton
                class="mx-1 text-nowrap"
                color="danger"
                square
                v-on:click="showConfirm(item.id)"
                variant="outline"
                size="sm"
                v-b-modal.delete-modal-TimgingMail
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listTimingMail.total"
      >
        <p class="font-weight-bold">
          {{ listTimingMail.total }}件中{{ listTimingMail.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listTimingMail.last_page"
      >
        <CPagination
          v-if="listTimingMail.total"
          :activePage.sync="page"
          :pages="listTimingMail.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-TimgingMail" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>削除しますと復元できませんのでご注意ください</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="removeTimingById(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-TimgingMail')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
// import $ from "jquery";
export default {
  name: "listTimingMail",
  data() {
    return {
      fields: tableFields.TIMING_MAIL,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      management_name: "",
      email_title: "",
      messageError: {},
      messageResponse: "",
      messagerErr: "",
      messageSuccess: "",
      dataTimgingMail: {
        id: "",
        shop_id: "",
      },
      limit: 50,
      page: 1,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
  },
  async created() {
    await this.getListTimingmails(1);
    this.lengthList = this.listTimingMail.total;
  },
  computed: {
    ...mapGetters(["listTimingMail", "message", "success", "error"]),
    lengthList() {
      if (this.listTimingMail) {
        return this.listTimingMail.total;
      }
      return null;
    },
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listTimingMail() {
      this.json_data = this.listTimingMail.data;
      this.page = this.listTimingMail.current_page;
    },
    page() {
      this.getListTimingmails(this.page);
    },
  },
  methods: {
    ...mapActions({ getListTimingmail: "getListTimingmail" }),
    ...mapActions({ deleteTimingMail: "deleteTimingMail" }),
    createUser() {
      this.$router.push({ name: "create user" });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    async getListTimingmails(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          management_name: this.management_name,
          title: this.email_title,
          limit: this.limit,
        },
      };
      await this.getListTimingmail(formData);
    },
    changeLimitPage() {
      this.getListTimingmails(1);
    },
    resetFieldSearch() {
      this.management_name = "";
      this.email_title = "";
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          management_name: this.management_name,
          title: this.email_title,
          limit: this.limit,
        },
      };
      this.getListTimingmail(formData);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    async removeTimingById(id) {
      this.dataTimgingMail.id = id;
      this.dataTimgingMail.shop_id = this.shop_id;
      const dataReturn = await this.$store.dispatch(
        "deleteTimingMail",
        this.dataTimgingMail
      );
      if (dataReturn.success) {
        this.$nextTick(() => {
          this.$bvModal.hide("delete-modal-TimgingMail");
          this.getListTimingmails(this.page);
        });
      } else {
        this.$toasted.error(dataReturn.message);
      }
    },
    CreateTimingEmail() {
      this.$router.push({
        name: "Create TimingMail",
        query: { no: this.lengthList + 1 },
      });
    },
  },
};
</script>
